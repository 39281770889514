import React from 'react';
import { injectIntl } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';

const TermsPage = ({ intl }) => (
  <Layout>
    <SEO
      title={intl.formatMessage({ id: 'blocto.terms.meta.title' })}
      description={intl.formatMessage({ id: 'blocto.terms.meta.description' })}
      path="terms"
    />
    <div className="container">
      <div className="content">
        <div className="title">
          <h1>Terms of Use</h1>
        </div>

        <h2>
          <b>Summary</b>
        </h2>
        <p>
          The following key points of these Terms of Use are brought for your
          convenience only. They do not substitute the full Terms.
        </p>
        <ol>
          <li>
            <b>Who we are. </b>
            {
              process.env.GATSBY_TERM_WHO_WE_ARE ||
              'Blocto is owned and operated by portto Co., Ltd. (門戶科技股份有限公司, Tax ID: 85089687)'
            }
          </li>
          <li>
            <b>What is Blocto. </b>
            Blocto is a mobile application that allows users to conveniently and
            securely access blockchain, use DApps, send and receive their crypto
            and digital assets.
          </li>
          <li>
            <b>Important disclaimers</b>
            .Blocto is not intended as, and does not provide, any investment or
            financial advice whatsoever. As with any financial or investment
            decisions, you should conduct your own research and due diligence
            investigation, to properly evaluate the benefits and risks of any
            investment or financial transaction. You should also seek the advice
            and guidance of qualified accountants, financial advisors, tax
            advisors, legal counsels and investment advisors, in connection with
            any investment or financial transaction.
          </li>
          <li>
            <b>Privacy. </b>
            We respect your privacy as further explained in our{' '}
            <a href="https://portto.com/privacy/">Privacy Policy</a>.
          </li>
          <li>
            <b>Intellectual property. </b>
            All legal rights in Blocto, including all intellectual property
            rights, are owned by, or authorized to portto.{' '}
          </li>
          <li>
            <b>Disclaimer of warranty.</b> Blocto is provided for use ‘as is’.
            We disclaim all warranties and representations with respect to
            Blocto.{' '}
          </li>
          <li>
            <b>Limitation of liability.</b> To the maximum extent permitted by
            the applicable law, we – and anyone acting on our behalf – will not
            be liable for any damage or loss, arising from or in connection with
            the use or inability to use Blocto.{' '}
          </li>
          <li>
            <b>Law & jurisdiction.</b> Use of Blocto is governed by the laws of
            the Cayman Islands and subject to the exclusive jurisdiction of
            courts located in the Cayman Islands.
          </li>
          <li>
            <b>Termination.</b> You may request to terminate your account at any
            time by contacting us at{' '}
            <a href="mailto:support@blocto.app">support@blocto.app</a>. Upon
            termination of these Terms or your account, your right to use Blocto
            is terminated and you must immediately cease using Blocto.
          </li>
          <li>
            THE TERMS INCLUDE ADDITIONAL PROVISIONS THAT YOU SHOULD CAREFULLY
            READ, SUCH AS PROVISIONS REGARDING WARRANTY, LIMITED LIABILITY,
            INDEMNIFICATION AND ASSIGNMENT.
          </li>
        </ol>

        <h2>
          <b>The Details</b>
        </h2>
        <p>
          Welcome to <b>Blocto</b>, a mobile application ("
          <b>App</b>
          ") that allows users ("
          <b>Users</b>" or "<b>you</b>
          ") to conveniently and securely access blockchain, use decentralised
          apps ("DApps"), send and receive crypto and digital assets ("
          <b>Assets</b>
          ").{' '}
        </p>
        <p>
          Blocto is owned and operated by <b>portto Co., Ltd.</b> ("
          <b>portto</b>
          ", "<b>we</b>
          ", "<b>us</b>" and "<b>our</b>
          ").{' '}
        </p>
        <p>
          Please read the following Terms Of Use (the "<b>Terms</b>
          ") carefully. By signing up to, accessing, or using Blocto, you agree
          to these Terms. If you do not agree to these Terms, you shall not
          access or use Blocto.
        </p>

        <h3>HOW DOES IT WORK?</h3>
        <p>
          Unlike traditional decentralized wallets, Blocto offers its Users a
          tool to conveniently and securely sign transactions ("
          <b>Transactions</b>
          ") from multiple devices with no private key for Users to manage ("
          <b>Private Key</b>
          "). Blocto uses a mix model of Private Key management and a
          multi-signature wallet to sign transactions. Once User signs a
          transaction, Blocto helps put it on blockchain and pay for the
          transaction fees incurred.
        </p>
        <p>
          In case Users’ devices are unavailable — e.g. if the device is broken
          or stolen — Blocto provides a way to assist Users to securely retrieve
          their device key by using email verification. The recovery process
          will be available to Users provided they have fully completed the
          email registration process in accordance with the instructions
          presented on Blocto.
        </p>
        <p>
          Users have an option to transform their Blocto wallet into a fully
          decentralized wallet. Blocto will help Users complete the process and
          backup the new Private Key securely. Once the process is complete,
          Blocto will no longer be able to help those Users retrieve their
          Private Key and will no longer take any responsibility if such Users
          lose their Private Key afterwards.
        </p>
        <p>
          Through Blocto, Users can also access and view the balance of their
          Assets. The fiat value of User’s Assets as displayed through Blocto is
          only an estimate depending on relevant exchange rates of Fiat Currency
          and the valuation of crypto and digital assets which are constantly
          changing. Portto does not warrant nor does it make any representations
          as to the accuracy of the fiat value displayed through Blocto. For the
          avoidance of any doubt, "Fiat Currency" means any currency issued by a
          central bank of sovereign countries, such as the US Dollar, Euro, etc.
        </p>

        <h3>IMPORTANT DISCLAIMERS</h3>
        <p>
          Blocto is not intended as, and does not provide, any investment or
          financial advice whatsoever. With respect to any financial or
          investment decisions, it is strongly recommended that you conduct your
          own research and due diligence investigation, to properly evaluate the
          benefits and risks of any investment or financial transaction. It is
          also strongly recommended that you seek the advice and guidance of
          qualified accountants, financial advisors, tax advisors, legal
          counsels and investment advisors, in connection with any investment or
          financial transaction.
        </p>

        <h3>REGISTRATION</h3>
        <p>
          <b>Information you provide. </b>
          In order to use the complete features of Blocto, you must be an
          individual who having reached the age of majority in applicable
          jurisdictions and register with a personal user account. When you
          register to Blocto, we will ask you to provide us with the details we
          describe in our{' '}
          <a href="https://portto.com/privacy/">Privacy Policy</a> ("
          <b>Registration Information</b>
          "). Blocto’s complete features are activated and available only to
          users who have successfully completed the sign-on process.{' '}
        </p>
        <p>
          Use of Blocto may not be available to you, in whole or in part, in
          certain regions, countries, or jurisdictions, in order to comply with
          certain laws, rules and regulations.{' '}
        </p>
        <p>
          <b>False information. </b>
          If we believe that the Registration Information you provide is false,
          deceptive or offensive, or if we believe that you violated these
          Terms, we reserve the right to suspend or terminate your User account
          or your access to Blocto.
        </p>
        <p>
          <b>Additional information. </b>
          We reserve the right to request additional information to verify your
          identity, during the registration process, throughout your use of
          Blocto or when you submit requests related to your Blocto account.{' '}
        </p>
        <p>
          <b>
            By registering for an account or accessing the service of the App,
            you represent and warrant that:
          </b>
        </p>
        <ul>
          <li>
            You are an individual, a legal person or another entity with full
            capacity and you have accepted the Terms in accordance with the
            applicable laws. If you do not meet such qualification, you and your
            legal representative shall take all responsibilities for all
            consequences arising thereof. We reserve the right to delete or
            permanently freeze your account and claim compensation for damages,
            including but not limited to attorneys’ fees, against you and your
            legal representative;
          </li>
          <li>
            Pursuant to applicable laws, you have the right to use the services;
          </li>
          <li>
            Your account has not previously been suspended or removed from the
            App;
          </li>
          <li>
            You own all virtual digital currency used by you on this App or you
            have been authorized to conduct the virtual digital currency
            transactions;
          </li>
          <li>
            You have all necessary consents and authorizations to carry out all
            transactions initiated from your account;
          </li>
          <li>
            Any information you submit to the App is true and complete, and will
            be updated at our request;
          </li>
          <li>
            To the extent allowed by law, you allow us to use your information
            for the purpose of law enforcement measures or legal compliance to
            facilitate the operation of this App;
          </li>
          <li>
            You do not intend on using the App (including the services and the
            account on the App) for any illegal purpose, including but without
            limitation to illegal gambling, money-laundering, fraud, blackmail,
            funding terrorism, and you will not engage in misconduct that may
            cause damage to our reputation.
          </li>
          <li>
            You will abide by applicable laws and any international practices
            relating to the use of internet, and will not carry out any conduct
            to infringe others’ rights while using the App;
          </li>
          <li>
            You are obligated to confirm the applicable laws for the virtual
            digital currency transactions you are involved in, including but not
            limited to the applicable laws and regulations regarding anti-money
            laundering, securities and taxation;
          </li>
          <li>
            If you violate any applicable laws, you shall be solely liable for
            any liabilities. If portto suffers any loss or damage resulting from
            your violation of these Terms, we may claim compensation against you
            for such loss and damage, including but not limited to litigation
            expense, attorneys’ fee and the loss of goodwill.)
          </li>
        </ul>
        <p>
          Prior to your using the App or an account on the App, we may request
          your personal information to verify your identification, and may
          further request you provide such information for our partners, banks
          in cooperative relationships, or establish an account in such banks to
          comply with our Know Your Customer(KYC) and anti-money laundering
          policy.
        </p>
        <p>
          If any User violates any provision in the Terms, his or her account,
          and the services on the App are likely to be frozen, canceled or
          terminated, and such User will be held liable for our damages, other
          Users’ or our partners’ loss.
        </p>

        <h3>YOUR PRIVACY</h3>
        <p>
          We respect your privacy. Please find our{' '}
          <a href="https://portto.com/privacy/">Privacy Policy</a> ("Policy")
          for the details of our privacy practices. The Policy is a part of the
          Terms and we encourage you to read the Policy carefully.
        </p>

        <h3>ACCEPTABLE AND PROHIBITED USE OF BLOCTO</h3>
        <p>
          <b>Accepted use.</b> The following terms define the acceptable use of
          the App and the content available therein. By using the App, you agree
          to abide by all applicable laws.{' '}
        </p>
        <p>
          <b>Account suspension. </b>
          We may temporarily or permanently suspend the use of your account or
          revoke your account, if we believe you have violated these Terms or
          have any behavior that raises suspicion of any criminal activity.
        </p>
        <p>
          <b>Prohibited use. </b>
          When using Blocto, you must refrain from –
        </p>
        <ul>
          <li>
            Breaching these Terms or any other applicable rules and instructions
            that we may convey with respect to Blocto;{' '}
          </li>
          <li>
            Interfering with, burdening or disrupting the functionality of
            Blocto;
          </li>
          <li>
            Breaching the security of Blocto or publicly identifying any
            security vulnerabilities in it;
          </li>
          <li>
            Circumventing or manipulating the operation or functionality of
            Blocto, or attempting to enable features or functionalities that are
            otherwise disabled, inaccessible or undocumented in Blocto;
          </li>
          <li>
            Sending automated or machine generated queries or commercial
            advertisements;
          </li>
          <li>
            Using robots, crawlers and similar applications to collect and
            compile content from Blocto or send data to Blocto including for the
            purposes of competing with Blocto, or in such ways that may impair
            or disrupt Blocto’s functionality;{' '}
          </li>
          <li>
            Displaying or embedding content from Blocto, including without
            limitation, by any software, feature, gadget or communication
            protocol which alters the content or its design;{' '}
          </li>
          <li>
            Impersonating any person or entity, or making any false statement
            pertaining to your identity or affiliation with any person or
            entity;{' '}
          </li>
          <li>
            Collecting, harvesting, obtaining or processing personal information
            regarding Blocto’s Users, without their prior explicit consent;
          </li>
          <li>
            Abusing, harassing, threatening or intimidating other Users of
            Blocto;
          </li>
          <li>
            Gaining or attempting to gain, unauthorized access to the App,
            accounts of other Users or any other component of the App;
          </li>
          <li>
            Causing a disproportionately large traffic on our infrastructure;
          </li>
          <li>
            Making the App available over a network, or otherwise permitting
            access to or use thereof by multiple Users at the same time;
          </li>
          <li>
            Making any unauthorized use of proprietary information or
            intellectual property of portto.
          </li>
          <li>
            Uploading any material or the App that contains computer viruses,
            Trojan horses or worms;
          </li>
          <li>
            Linking to Blocto from web pages or applications that contain
            pornographic content or content that encourages racism or wrongful
            discrimination;
          </li>
          <li>
            Engaging in any activity that constitutes a criminal offense or
            gives rise to any civil liabilities;
          </li>
          <li>
            Transferring your account on Blocto to another person without our
            prior written consent;
          </li>
          <li>
            Attacking the App or decompile, reverse engineer, disassemble or
            decrypt the App, or attempt to derive the source code of the App;
          </li>
          <li>
            Making any modification, adaptation, improvement, enhancement,
            translation, or derivative work to or from the App;
          </li>
          <li>
            Removing, altering, or obscuring any proprietary notice of portto,
            its suppliers, partners, or licensors;
          </li>
          <li>
            Using the App for any other purpose for which it is not designed or
            intended; or
          </li>
          <li>Violating any applicable laws, rules or regulations;</li>
        </ul>
        <p>
          You are solely responsible for the content you make available through
          Blocto and for the consequences associated with doing so.{' '}
        </p>
        <p>
          If any User conducts prohibited use, his or her account, and the
          services on the App are likely to be frozen, canceled or terminated,
          and such User will be held liable for our damages, other Users’ or our
          partners’ loss.
        </p>
        <p>
          If any suspicious activity is detected in the services or account you
          accessed into on the App, we may request additional information from
          you (including verification documents) and may freeze, cancel or
          terminate any transaction or your account on the App. It is your
          obligation to satisfy the foregoing security requirements, or accept
          the suspension or termination imposed by the App. In the event of any
          unauthorized or prohibited use of the account or password on the App,
          please inform us by emailing at{' '}
          <a href="mailto:support@blocto.app">support@blocto.app</a>.
        </p>

        <h3>FEE-BASED AND FREE OF CHARGE SERVICES</h3>
        <p>
          Access to Blocto is offered to you free for life during the launch
          period ("
          <b>Free Access</b>" and "<b>Launch Period</b>
          "). Additional features and services introduced in the future will not
          be included as part of your Free Access, but will be subject to
          payment of the applicable fees ("
          <b>Fees</b>
          "), in accordance with the packages, schemes and amounts presented to
          you, either upon registration or at a later time.
        </p>
        <p>
          <b>Trial-period.</b> Features offered subject to payment of the
          applicable fees, may be offered on a trial-basis, free-of-charge,
          which may be time-limited. We may, at any time and at our sole
          discretion, change any rates and payment terms.
        </p>
        <p>
          <b>Fee-based services.</b> Failing to settle the payment of Fees will
          prevent you from further using Blocto, notwithstanding any other
          remedies available to us under the applicable law. In such a case, we
          may terminate your Blocto account and your use of its features.
        </p>
        <p>
          All Fees are quoted in US Dollars, unless expressly stated otherwise.
        </p>
        <p>
          We will charge you for the applicable Fees (if applicable), as they
          become due, using the payment method you provide during registration
          ("
          <b>Payment Method</b>
          "). By providing your Payment Method, you represent and warrant that
          you are lawfully permitted to use the selected Payment Method in
          connection with your use of Blocto.
        </p>
        <p>
          We may require additional information from you before completing
          payment transactions. You must keep the Payment Methods you provided
          to us upon registration current, complete, and accurate, and notify us
          promptly in case of any change in your Payment Method or other details
          you have provided us.
        </p>
        <p>
          Your Payment Method may be processed and handled through a third-party
          payment processors, such as credit card service providers and payment
          processors (e.g. PayPal). Your Payment Method is therefore subject to
          the terms and conditions of these third parties pursuant to your
          contractual relations with them.
        </p>
        <p>
          You acknowledge that the third-parties processing any of the Payment
          Methods may charge you commission on their end of the transaction. We
          are not responsible for such commission, which is strictly within your
          contractual relations with the relevant payment processor. We will not
          be liable for mistakes, errors, malfunctions or miscalculations that
          you or third-party payment processors may make in the course of a
          payment transaction.
        </p>
        <p>
          We may, from time to time, and without specific notice to you, add
          additional Payment Methods to the then-current Payment Methods, or
          cease to use previously supported Payment Methods.
        </p>
        <p>
          By registering to Blocto and confirming the packages, schemes and
          amounts presented to you, you give your consent for being billed for
          the applicable Fees, in addition to any applicable taxes (such as
          sales tax, value added tax or withholding tax), and any surcharges or
          commissions charged by the payment processor or your Payment Method.
        </p>
        <p>
          All amounts payable to us are exclusive of any excise, sales tax, VAT,
          withholding tax or other governmental charges or transaction charges.
          You are responsible for the payment of all such applicable taxes or
          charges. You shall promptly provide us with the taxation documentation
          necessary for processing the Fees upon our request.
        </p>
        <p>
          To the maximum extent permitted by applicable law, the Fees paid by
          you are non-refundable. You are responsible for paying all applicable
          Fees regardless of whether you actually accessed or otherwise
          benefited from your use of Blocto. Failure to settle any overdue Fees
          within thirty (30) calendar days of its original due date will
          constitute a material breach of these Terms, and may cause us to
          suspend, disable or terminate your account, in addition to any other
          remedies available to us under the applicable law.
        </p>

        <h3>INTELLECTUAL PROPERTY</h3>
        <p>
          <b>Our intellectual property. </b>
          All rights, title and interest in and to the Blocto, including without
          limitation, patents, copyrights, trademarks, trade names, service
          marks, trade secrets, software, program, content, publication, image,
          file, information, data, structure of the App, App layout, and App
          design and other intellectual property rights and any goodwill
          associated therewith, are the exclusive property of portto or its
          licensors.
        </p>
        <p>
          <b>Restrictions. </b>
          You may not copy, reproduce, broadcast, reverse engineer, modify or
          create derivative works of, public display and distribute, publish,
          disassemble and decide portto’s or its licensors’ intellectual
          property in any way or by any means without proper authorization.{' '}
        </p>
        <p>
          You may not use any name, mark, logo or domain name that is
          confusingly similar to our marks, logos and internet domain names. You
          must refrain from any action or omission that may dilute or damage our
          goodwill.{' '}
        </p>
        <p>
          You will be held liable for our damage (including but not limited to
          attorneys’ fee) in the event you infringe porttos’ or its licensors’
          intellectual properties.
        </p>
        <p>
          When a User uploads content to our App (including but not limited to
          copyright, trademark or other contents), he or she gives a free
          license to portto to use such content for the purpose of operating our
          App and providing our services. Users shall guarantee all contents
          posted or uploaded to the App do not infringe any third party’s
          copyright, trademark and other rights or interest. If such
          infringement takes place, the User shall be responsible for all civil
          and criminal liabilities, and portto shall be held harmless and fully
          indemnified; if any loss occurs to portto, the User shall also liable
          for damages to portto (including but not limited to attorneys’ fee).
          If any third party informs portto of the foregoing right infringement,
          portto may remove the content in dispute.
        </p>

        <h3>ACCOUNT MANAGEMENT</h3>
        <p>
          Blocto account is a multi-signature smart contract-based account.
          Double signatures are required for transactions consummated by Blocto
          account. Users may initiate a transaction with the signature using
          their private key that is saved on their device. For the purpose of
          heightening the security of Blocto account, the private key on Blocto
          system will be used for a second signature once a transaction is
          initiated, and Blocto will pay the processing fee on behalf of the
          User initiating such transaction. For clarity, any transactions and
          uses of funds may only be initiated by Users, and Blocto pays the
          processing fee on behalf of Users just for administrative purposes.
        </p>
        <p>
          The App provides a digital wallet for virtual digital currency
          transaction between you and your counterparty. portto is not a party
          to the contract of such transaction or any other contract between you
          and your counterparty, and portto bears no legal liability for these
          contracts. You and your counterparty shall take full responsibility
          arising from their contract and information regarding your trade.
          portto is not involved in any User’s transaction, and does not
          guarantee or otherwise acquiesce to guarantee any performance of such
          transaction. In the event disputes arise between you and your
          counterparty, please resolve the matter by yourself; portto will not
          be involved in such disputes. However, if portto receives complaints
          or obtains any knowledge regarding any Users involved in fraud,
          transaction disputes or illegal misconduct, portto has the right to
          adopt necessary measures to protect the rights of Users and its own
          rights as well. portto can screen out Users and information or content
          provided by Users. portto reserves the right to remove any content or
          information you provide to the App. portto does not ensure Users will
          be able to consummate transactions.
        </p>
        <p>
          portto is highly concerned about the security of the App and our
          services. By registering on the App, you agree to provide your latest
          and accurate personal information, and also agree to continue to
          update such information. You acknowledge you will not allow any others
          access into your account on the App at any time.
        </p>
        <p>
          Any intent to or assistance in carrying out misconduct by using an
          account, whether by using instruction manuals, software or tools will
          also result in termination of such account. Apart from terminating
          such account, we may also take further actions to counter such
          violation.
        </p>
        <p>
          You are also responsible for the maintenance of the security of your
          account information, including your password and transaction record.
          You shall ensure the safety and confidentiality of your login
          credential, and prevent any unauthorized access to your account. If we
          learn of any suspicious activity in your account, we will freeze your
          account, and may request additional information from you, including
          verification documents, for our further procedures. You are obligated
          to comply with such safety requests and agree for us to freeze your
          account. If you learn of any unauthorized use of your account or
          password, or violation of confidentiality agreement, please inform
          portto via email support@blocto.app. Any User who violates such rules
          is subject to termination of the account, and bears the damages and
          legal liability to portto and its Users.
        </p>
        <p>
          You agree and acknowledge you will not carry out any forms of criminal
          conduct by using the App or our services, including but not limited to
          money laundering, illegal gambling, financing terrorism or malicious
          hacking activity.
        </p>
        <p>
          You agree portto has the right to terminate your use of our services,
          and close your account on the App promptly following the occurrence of
          any matter set forth below:
        </p>
        <ul>
          <li>
            Trying or assisting others to access other Users’ account on the App
            without authorization;
          </li>
          <li>
            Making changes, deleting or demolishing data, content and equipment
            on the App or causing adverse effect on the App’s business operation
            by using robot account or other method;
          </li>
          <li>
            Modifying, deleting or collecting others’ personal information
            without authorization by taking advantage of any existing defect of
            the App, sabotaging or trespassing the security system;
          </li>
          <li>
            Carrying out unlawful misconduct, including money laundering,
            illegal gambling, financing terrorism or other criminal activity;
          </li>
          <li>Violation of the Terms;</li>
          <li>
            Defaulting on payment or conducting fraudulent transaction; or
          </li>
          <li>Difficulties in operation.</li>
        </ul>
        <p>
          Once portto takes legal action at the request of any governmental
          authority or pursuant to the law, the matter shall be determined at
          portto’s sole discretion.
        </p>
        <p>
          You further agree that portto can terminate or suspend access to your
          account and the App at any time upon its sole discretion. If portto
          reckons that any User may cause any legal liability, or any User’s
          conduct does not conform to the purpose of the App (portto has sole
          discretion on this matter) it can exclude such User from the App by
          taking legal or technical measures. portto shall not be held liable
          for unilaterally suspending User’s account or restricting User’s
          access to the App to any User or third party. Such suspension or
          restriction shall not affect the Fees payable accrued before such
          cancellation or suspension and portto shall not be held liable for any
          loss or damages arising from such cancellation or suspension.
        </p>
        <p>
          In the event any User engages in fraud or any other unlawful act and
          is being investigated by the police or other law enforcement or
          judicial agency,portto will provide transaction records and the
          personal information (including personal identification card, bank
          account information that is on record) of the accused to the police or
          other law enforcement or judicial agency.
        </p>

        <h3>RISK</h3>
        <p>
          portto hereby disclaims all responsibility for any loss or damage
          arising from or relating to your use of the App (including, but not
          limited to, risk of losses due to factors beyond its control regarding
          the viability of any specific blockchain network). portto further
          disclaims all responsibility for any loss or damages arising from or
          relating to any cyber-attacks (including without limitation the theft
          of your personal information), unprecedented surges in trading volume,
          any disruption or shut down of the services, or other technical
          difficulties with respect to the services.
        </p>
        <p>
          You acknowledge that portto’s data reservation and User verification
          procedures may change at any time as required by law or according to
          the latest industry practice. We may not give notices for such
          changes.
        </p>

        <h3>LIMITED LICENSE</h3>
        <p>
          portto grants you a limited, nonexclusive, nontransferable license to
          access and use the App; however, portto reserves the right to transfer
          all its rights, interests or obligations under these Terms to any
          person and these Terms shall continue to be in force and effect for
          the benefit of the successors and assigns of portto.
        </p>
        <p>
          You may access and use the App solely for the intended functions of
          our services and other approved purposes as expressly permitted by
          portto. Any other use of the App is expressly prohibited.
        </p>

        <h3>SECURITY OF THE PLATFORM</h3>
        <p>
          You acknowledge that information you store or transfer through
          Blocto’s services may become irretrievably lost or corrupted or
          temporarily unavailable due to a variety of causes, including software
          failures, protocol changes by third-party providers, internet outages,
          force majeure event or other disasters including third-party DDOS
          attacks, scheduled or unscheduled maintenance, or other causes either
          within or outside portto’s control. You are solely responsible for
          backing up and maintaining duplicate copies of any information you
          store or transfer through Blotco’s services.
        </p>

        <h3>COMPLIANCE BY USER</h3>
        <p>
          You acknowledge and agree that portto is not responsible for
          determining whether or which laws, rules, or regulations apply or may
          apply to your transactions (including, without limitation, any
          anti-money laundering laws, securities laws and tax laws). You
          acknowledge and agree that you are solely responsible for compliance
          with all such laws rules, or regulations as may be applicable to your
          transactions. Without limiting the foregoing, you acknowledge and
          agree that you are solely responsible for all tax obligations arising
          from your use of our services. You further acknowledge and agree that
          portto shall not be liable, whether directly or indirectly, for any of
          your tax obligations.
        </p>

        <h3>REGULATORY COMPLIANCE</h3>
        <p>
          You acknowledge and agree that portto’s recordkeeping and Users
          verification procedures may be, without prior notice, subject to
          change at any time as required by applicable regulations or state of
          the art practices.
        </p>
        <p>
          Applicable law, regulation, and executive orders may require portto
          to, upon request by government agencies, freeze or suspend Users’
          account, or disclose information regarding your accounts. In the event
          such disclosure is compelled, you agree that portto may disclose
          information regarding your accounts. While portto will endeavor to,
          where commercially reasonable, give you prior notice of such
          disclosure, portto makes no guarantees that such prior notice will be
          made.
        </p>

        <h3>APPLICATION MARKETPLACE</h3>
        <p>
          Your use of the Blocto may be subject to additional third-party terms
          and conditions that govern that application marketplace from which you
          downloaded the App, such as iTunes. Such third parties are not
          responsible for providing maintenance and support services with
          respect to the App.
        </p>
        <p>
          The following terms apply if you downloaded an App from Apple’s App
          Store. You agree and acknowledge as follows:
        </p>
        <p>
          These Terms are concluded between yourself and us, and not with Apple
          Inc. ("Apple"). Apple is not responsible for the App. In the event of
          a conflict between these Terms and the App Store Terms of Service then
          the App Store Terms of Service will prevail, solely with respect to
          the conflicting provisions.
        </p>
        <p>
          The license granted to you for the App is limited to a
          non-transferable license to use the App on any iOS Products that you
          own or control, and as permitted by the Usage Rules set forth in the
          App Store Terms of Service, except that such App may be accessed,
          acquired, and used by other accounts associated with the purchaser via
          Family Sharing.
        </p>
        <p>
          In the event of a failure to conform to any applicable warranty (if
          any warranty is applicable), you may notify Apple, and Apple will
          refund the purchase price for the App to you (if you paid any). Apple
          has no other warranty obligation whatsoever with respect to the App,
          and any other claims, losses, liabilities, damages, costs or expenses
          attributable to any failure to conform to any warranty, will not be at
          Apple’s responsibility.
        </p>
        <p>
          Apple is not responsible for addressing any claims by you or any third
          party relating to the App or your possession and/or use of the App,
          including (i) product liability claims, (ii) any claim that the App
          fails to conform to any applicable legal or regulatory requirement,
          and (iii) claims arising under consumer protection or similar
          legislation.
        </p>
        <p>
          In the event of any third-party claim that the App or your possession
          and use of the App infringes that third party’s IP Rights, Apple will
          not be responsible for the investigation, defense, settlement and
          discharge of any such infringement claim.
        </p>
        <p>
          You must comply with applicable third-party terms of agreement when
          using the App (e.g. you must not be in violation of your wireless data
          Services agreement when you use the App).
        </p>
        <p>
          Apple and Apple’s subsidiaries are third-party beneficiaries of these
          Terms. Upon Your acceptance of these Terms, Apple will have the right
          (and will be deemed to have accepted the right) to enforce these Terms
          against you as a third-party beneficiary thereof. You represent and
          warrant that (i) you are not located in a country that is subject to a
          U.S. Government embargo, or that has been designated by the U.S.
          Government as a "terrorist supporting" country; and (ii) you are not
          listed on any U.S. Government list of prohibited or restricted
          parties.
        </p>

        <h3>THIRD PARTY PLATFORMS</h3>
        <p>
          Blocto may interface with third-party services and platforms ("
          <b>Third Party Platforms</b>
          "). For example, you may "share" your activity on Blocto and invite
          your friends to use Blocto through social network Third Party
          Platforms. Other Third Party Platforms may provide you the ability of
          purchasing crypto with fiat currency.
        </p>
        <p>
          The following terms apply to services provided by Third Party
          Platforms.
        </p>
        <ul>
          <li>
            Use of these Third Party Platforms is governed by their respective
            terms of service, not by these Terms. You bear the sole and
            exclusive responsibility for accepting and complying with those
            other terms of service.
          </li>
          <li>
            The responsibilities, obligations and liabilities of Third Party
            Platforms are as indicated in their respective terms of service.
          </li>
        </ul>
        <p>
          This App may provide content of a third party or links to other
          external links, which may link to websites operated or marketed by
          other businesses. However, this does not imply that portto is
          associated with such businesses. portto does not control and endorse
          or guarantee the accuracy and intactness of the contents of a third
          party and its websites. portto is not responsible for the contents,
          privacy policies and practices of third-party websites. You shall bear
          any direct or indirect damages or losses arising out of your reliance
          on any content, product or services of such website or source, and
          portto is not liable for such losses. portto has no obligation to
          ensure that the links lead to completely risk-free websites. Sites
          other than this App may involve legal or other actual risks and you
          should take precautions to ensure that the website you opt to use is
          free of viruses, malicious programs, or other similar programs.
        </p>

        <h3>TERMINATION</h3>
        <p>
          <b>Terminating your account.</b> You may, at any time, request to
          terminate your account by contacting us at{' '}
          <a href="mailto:support@blocto.app">support@blocto.app</a>.{' '}
        </p>
        <p>
          Upon termination of these Terms or your account, your right to use
          Blocto is terminated and you must immediately cease using Blocto.
        </p>
        <p>
          <b>Termination from idleness.</b> Blocto reserves the right to
          terminate your account if you have been inactive for more than 30
          days.{' '}
        </p>
        <p>
          <b>Effects of Termination</b>. Upon termination, your User account
          will be cancelled.
        </p>
        <p>
          <b>Terminating the operation of Blocto</b>. We may at any time
          discontinue or terminate the operation of Blocto, or any part thereof,
          temporarily or permanently, for all Users, or for certain Users,
          without any liability to you. If we do so on our own accord and not as
          a result of your violation of these Terms, we will notify you in
          advance before such termination.
        </p>

        <h3>CHANGES AND AVAILABILITY</h3>
        <p>
          <b>Changes on Blocto. </b>
          We may, at any time and without prior notice change the layout,
          design, scope, features or availability of Blocto. We may also remove
          User content if we deem it necessary for operational reasons.
        </p>
        <p>
          <b>Availability.</b> The availability, functioning, quality and
          functionality of Blocto depend on various factors, including software,
          hardware and communication networks, which are provided by third
          parties, at their responsibility. These factors are not fault-free.
        </p>
        <p>
          <b>Changing these Terms.</b> We may revise these Terms (beyond the
          terms, rules and policies explained in our user guide), in whole or in
          part, at any time by notifying you of the amended Terms ahead of time.
          Your continued use of Blocto after the effective date of the amended
          Terms constitutes your consent to the amended Terms.{' '}
        </p>

        <h3>DISCLAIMER OF WARRANTY</h3>
        <p>
          BLOCTO IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITH ALL
          FAULTS AND PORTTO RESERVES THE RIGHT TO PARTIALLY OR COMPLETELY MODIFY
          THE CONTENTS OF THE SERVICES ON THE APP AT ANY TIME. WE AND OUR
          OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS, AGENTS
          AND AFFILIATES (THE "STAFF") DISCLAIM ALL WARRANTIES AND
          REPRESENTATIONS, EITHER EXPRESS OR IMPLIED, WITH RESPECT TO BLOCTO,
          ITS CONTENT, THE FEES, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES
          OF MERCHANTABILITY, AND FITNESS FOR A PARTICULAR PURPOSE, QUALITY,
          NON-INFRINGEMENT, TITLE, COMPATIBILITY, PERFORMANCE, SECURITY,
          ACCURACY, ENHANCED GOODWILL OR EXPECTED BENEFITS.
        </p>
        <p>
          WE DO NOT WARRANT THAT (1) BLOCTO WILL OPERATE UNINTERRUPTEDLY,
          ERROR-&shy;FREE OR WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS IN ANY
          WAY; (2) BLOCTO WILL ALWAYS BE AVAILABLE OR FREE FROM MALWARES,
          COMPUTER VIRUSES OR OTHER HARMFUL COMPONENTS; (3) THE QUALITY OF
          BLOCTO AND THE CONTENT AVAILABLE THROUGH IT, WILL MEET YOUR
          EXPECTATIONS; (4) THE CONTENT PRESENTED ON BLOCTO WILL BE ACCURATE,
          BENEFICIAL OR RELIABLE; (5) THE RESULTS OF USING BLOCTO WILL BE
          SATISFACTORY AND WILL FIT YOUR EXPECTATIONS OR REQUIREMENTS.
        </p>
        <p>
          NO REPRESENTATION OR OTHER AFFIRMATION OF FACT, INCLUDING STATEMENTS
          REGARDING CAPACITY, SUITABILITY FOR USE OR PERFORMANCE OF BLOCTO, OR
          THE CONTENT PRESENTED ON, OR THROUGH, BLOCTO, WHETHER OR NOT MADE BY
          ANY OF OUR STAFF, WHICH IS NOT EXPRESSLY CONTAINED IN THESE TERMS,
          SHALL BE DEEMED TO BE A WARRANTY BY THE INVOLVED PERSONS FOR ANY
          PURPOSE, OR GIVE RISE TO ANY LIABILITY OF OUR STAFF WHATSOEVER.
        </p>
        <p>
          PORTTO DOES NOT OWN OR CONTROL ANY OF THE UNDERLY SOFTWARE THROUGH
          WHICH ASSETS ARE CREATED AND TRANSACTED, SUCH AS BLOCKCHAIN
          TECHNOLOGY. PORTTO DOES NOT MAKE ANY GUARANTEE OF THE FUNCTIONALITY,
          SECURITY OR AVAILABILITY OF SUCH SOFTWARE OR TECHNOLOGY.YOU AGREE AND
          ACKNOWLEDGE THAT THE USE OF BLOCTO IS ENTIRELY, OR AT THE MAXIMUM
          PERMITTED BY THE APPLICABLE LAW, AT YOUR OWN RISK.
        </p>

        <h3>TAXATION</h3>
        <p>
          Users acknowledge and agree that all tax liabilities associated with
          the transaction of Assets shall be borne by the parties thereto in
          accordance with relevant laws, and portto does not provide any law or
          tax advice. In addition, given that tax laws and regulations may be
          updated at any time, Users shall immediately seek professional advice
          should he or she encounter any doubt. All tax related issues that you
          come across during transactions with Blocto wallet are dealt in
          accordance with applicable laws. At the request of the tax
          authorities, portto shall provide the information of Users or withhold
          taxes of same.
        </p>

        <h3>LIMITATION OF LIABILITY</h3>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR STAFF,
          SHALL NOT BE LIABLE, FOR ANY INDIRECT, SPECIAL, PUNITIVE, EXEMPLARY,
          STATUTORY, INCIDENTAL OR CONSEQUENTIAL DAMAGE, OR ANY SIMILAR DAMAGE
          OR LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES
          AND PAYMENTS, EITHER IN TORT (INCLUDING NEGLIGENCE), CONTRACT, OR IN
          ANY OTHER FORM OR THEORY OF LIABILITY, ARISING FROM, OR IN CONNECTION
          WITH THE CONTENT, THE FEES, THE USE OF, OR THE INABILITY TO USE BLOCTO
          OR ITS FEATURES, OR FROM ANY FAILURE, ERROR, OR BREAKDOWN IN THE
          FUNCTION OF BLOCTO, OR FROM ANY FAULT, OR ERROR MADE BY OUR STAFF, OR
          FROM YOUR RELIANCE ON CONTENT AVAILABLE ON BLOCTO, OR FROM ANY
          COMMUNICATION THROUGH BLOCTO, OR WITH OTHER USERS ON BLOCTO, OR FROM
          ANY DENIAL OR CANCELATION OF YOUR USER ACCOUNT, OR FROM RETENTION,
          DELETION, DISCLOSURE AND ANY OTHER USE OR LOSS OF YOUR CONTENT ON
          BLOCTO.
        </p>
        <p>
          IN ANY EVENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR
          TOTAL, MAXIMUM AND AGGREGATE LIABILITY TO YOU FOR DIRECT DAMAGES SHALL
          BE LIMITED TO HALF THE FEES YOU PAID US (IF ANY) IN THE TWELVE MONTHS
          PRECEDING THE EVENT PURPORTEDLY GIVING RISE TO THE CLAIM.
        </p>
        <p>
          NOTWITHSTANDING ANYTHING HEREIN TO THE CONTRARY, WE WILL BE FULLY
          RELEASED FROM OUR OBLIGATIONS AND LIABILITY TO YOU IF YOU HAVE
          BREACHED THESE TERMS, ANY OTHER TERMS, RULES OR REGULATIONS APPLICABLE
          TO BLOCTO, OR IF THROUGH YOUR USE OF BLOCTO, YOU INFRINGED OR VIOLATED
          ANY OTHER PERSON’S RIGHTS.
        </p>

        <h3>INDEMNIFICATION</h3>
        <p>
          To the maximum extent permitted by law, you will indemnify and hold
          harmless at your own expense, us, our Staff and anyone acting on our
          behalf, from and against any damages, costs and expenses, resulting
          from any claim, allegation or demand, connected with your use of
          Blocto, your breach of these Terms or infringement of any other
          person’s rights.
        </p>

        <h3>FORCE MAJEURE</h3>
        <p>
          Portto may cease to provide relevant services in cases of force
          majeure, changes in the law or policies or other factors beyond
          portto’s control that result in portto’s failure to perform the
          services of this App.
        </p>

        <h3>NOTICE</h3>
        <p>
          You consent to portto informing you of any notifications concerning
          the Terms and any other agreements, announcements, or any issues
          regarding your use of the account or services of this App through
          electronic means, such as by e-mail, posting on this App or a
          partner’s website or by wireless communication devices, etc. You agree
          the notice has been served on the date which this notice is
          transmitted through electronic means. portto is not liable for any
          failure to receive such notice on the date of transmission that
          results from information transmission or other reasons.
        </p>

        <h3>GOVERNING LAW, JURISDICTION</h3>
        <p>
          Regardless of your place of residence or where you access or use
          Blocto from, these Terms and your use of Blocto will be governed by
          and construed solely in accordance with the laws of the Cayman
          Islands.
        </p>
        <p>
          Courts located in the Cayman Islands of competent jurisdiction will
          have exclusive and sole jurisdiction over any dispute, claim or
          controversy relating to Blocto or with respect to any matter relating
          to these Terms. You hereby expressly consent to personal jurisdiction
          in the Cayman Islands and expressly waive any right to object to such
          personal jurisdiction or the non-convenience of such forum.
        </p>
        <p>
          Notwithstanding the foregoing, we may lodge a claim against you
          pursuant to the indemnity clause above in any court adjudicating a
          third-party claim against us.
        </p>

        <h3>GENERAL</h3>
        <p>
          <b>Assignment. </b>
          You may not assign or transfer your rights and obligations under these
          Terms without our prior written consent. Any attempted or actual
          assignment by you, without our prior written consent, shall be null
          and void.
        </p>
        <p>
          <b>Changes in ownership. </b>
          In the event of M&amp;A, we may, upon notice to you and without
          obtaining your consent, assign and delegate these Terms, including all
          of our rights, performances, duties, liabilities and obligations
          contained herein, to a third party.{' '}
        </p>
        <p>
          <b>Severability. </b>
          If any provision of these Terms is held to be illegal, invalid, or
          unenforceable by a competent court, then the provision shall be
          performed and enforced to the maximum extent permitted by law to
          reflect as closely as possible, the original intention of that
          provision, and the remaining provisions of these Terms shall continue
          to remain in full force and effect.
        </p>
        <p>
          <b>Interpretation</b>. The section headings in these Terms are
          included for convenience only and shall take no part in the
          interpretation or construing of these Terms. Whenever used in these
          Terms, the term "Including", whether capitalized or not, means without
          limitation to the preceding phrase. All examples and e.g. notations
          are illustrative, not exhaustive.
        </p>
        <p>
          <b>Entire agreement.</b> These Terms constitute the entire agreement
          between you and us concerning the subject matter herein, and supersede
          all prior and contemporaneous negotiations and oral representations,
          agreements and statements.
        </p>
        <p>
          <b>Waivers</b>. No waiver, concession, extension, representation,
          alteration, addition or derogation from these Terms by us, or pursuant
          to these Terms, will be effective unless consented to explicitly and
          executed in writing by our authorized representative. Failure on our
          part to demand performance of any provision in these Terms shall not
          constitute a waiver of any of our rights under these Terms.
        </p>
        <p>
          <b>Relationship</b>. These Terms do not create any agency,
          partnership, employment or fiduciary relationship between you and us.
        </p>

        <h3>CONTACT US</h3>
        <p>
          At any time, you may contact us with any question, request, comment or
          complaint that you may have with respect to Blocto or these Terms at{' '}
          <a href="mailto:support@blocto.app">support@blocto.app</a>.{' '}
        </p>
        <p>
          <em>Effective Date: October 25, 2021</em>
        </p>
      </div>
    </div>
  </Layout>
);

export default injectIntl(TermsPage);
